<template>
  <div class="products-table">
    <div v-if="products.length" class="position-table">
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <md-table v-model="products">
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          @click="openModal(item.product.id)"
        >
          <md-table-cell md-label="Название товара" class="custom-field">
            <span>{{ item.product.name }}</span>
          </md-table-cell>

          <md-table-cell
            v-if="item.product.brand"
            md-label="Бренд" class="custom-field"
          >
            <span>{{ item.product.brand.name }}</span>
          </md-table-cell>

          <md-table-cell md-label="Размер" class="custom-field">
            <span>{{ item.option.size }}</span>
          </md-table-cell>

          <md-table-cell md-label="Цвет" class="custom-field">
            <div
              :title="item.option.color.name"
              :style="{
                width: '60px',
                height: '20px',
                backgroundColor: item.option.color.hex,
              }"
            ></div>
          </md-table-cell>

          <md-table-cell md-label="Количество" class="custom-field">
            <span>{{ item.quantity }}</span>
          </md-table-cell>

          <md-table-cell md-label="Цена за шт." class="custom-field">
            <span>{{ item.price }}</span>
          </md-table-cell>

<!--          <md-table-cell v-if="showRemoveButtons">-->
<!--            <md-button-->
<!--              class="md-just-icon md-simple md-danger"-->
<!--              @click.stop="$emit('remove-product', item.product.id)"-->
<!--            >-->
<!--              <md-icon>delete</md-icon>-->
<!--              <md-tooltip md-direction="top">Удалить</md-tooltip>-->
<!--            </md-button>-->
<!--          </md-table-cell>-->
        </md-table-row>
      </md-table>
    </div>
    <div v-else class="my-empty empty">Пусто</div>

    <ModalProduct
      v-if="showProductModal"
      :product="product"
      @close="showProductModal = false"
    />
  </div>
</template>

<script>
import ModalProduct from "@/components/Modals/Product";

import { mapState, mapActions } from "vuex";

export default {
  components: {
    ModalProduct,
  },

  props: {
    products: {
      type: Array,
      default: () => [],
    },

    showRemoveButtons: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showProductModal: false,
    };
  },

  computed: {
    ...mapState({
      product: (state) => state.products.product,
    }),
  },

  methods: {
    ...mapActions("products", ["getProduct"]),

    getColors(options) {
      let colors = options.filter((option) => option.attribute_id == 2);
      return colors
        .flat()
        .map((color) => color.name)
        .join(", ");
    },

    getSizes(options) {
      let sizes = options.filter((option) => option.attribute_id == 1);
      return sizes
        .flat()
        .map((size) => size.name)
        .join(", ");
    },

    async openModal(product_id) {
      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.getProduct(product_id);
      if (success) {
        this.showProductModal = true;
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-table {
  margin-top: 20px;

  &::v-deep {
    .md-table-row {
      cursor: pointer;
    }
  }
}
</style>
