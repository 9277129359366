<template>
  <transition name="modal-fade">
    <div ref="modal" class="modal-backdrop" @click="clickOutside">
      <div class="modal" role="dialog">
        <div class="btn-close" @click="close">
          <md-icon>close</md-icon>
        </div>

        <div class="title-wrap">
          <h4 class="md-title">Заказ №{{ order.id }}</h4>
          <div class="subheading-wrap">
            <h5 class="md-headline">
              Статус заказа: {{ order.payment_status }}
              <span class="icon" @click="$router.push(`/orders/${order.id}`)">
                <md-icon> edit </md-icon>
              </span>
            </h5>
          </div>
        </div>

        <div class="md-layout">
          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Дата заказа:</span>
                {{ getDate(order.created_at) }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Ожидаемая дата доставки:</span>
                {{ getDate(order.delivery_date) }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Адрес доставки:</span>
                {{ order.delivery_address }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Тип доставки:</span>
                {{ order.delivery_type }}
              </p>
            </md-field>
          </div>

          <div
              v-if="order.delivery_type === 'cdek' && cdekPoint"
              class="md-layout-item md-large-size-50"
          >
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Название пункта выдачи:</span>
                {{ cdekPoint.name }}
              </p>
            </md-field>
          </div>

          <div
              v-if="order.delivery_type === 'cdek' && cdekPoint"
              class="md-layout-item md-large-size-50"
          >
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Адрес пункта выдачи:</span>
                {{ cdekPoint.address_full }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Трек-номер:</span>
                {{ order.track_number }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Статус доставки:</span>
                {{ getStatus(order.delivery_status) }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Тип оплаты:</span>
                {{ order.payment_type }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Статус оплаты:</span>
                {{ order.payment_status }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Имя пользователя:</span>
                {{ order.user.name }}
              </p>
            </md-field>
          </div>

          <div class="md-layout-item md-large-size-50">
            <md-field class="info-field">
              <p class="info">
                <span class="md-body-2">Номер телефона:</span>
                {{ order.user.phone | VMask("+# (###) ###-##-##") }}
              </p>
            </md-field>
          </div>
        </div>

        <OrderProductsTable :products="order.order_product" />
      </div>
    </div>
  </transition>
</template>

<script>
import OrderProductsTable from "@/components/Tables/OrderProductsTable";

import { formatISODate } from "@/helpers/formatDate";
import {mapState} from "vuex";

export default {
  components: {
    OrderProductsTable,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      cdekPoint: (state) => state.orders.cdekPoint,
    })
  },

  methods: {
    clickOutside(e) {
      if (e.target.classList.contains("modal-backdrop")) {
        this.$emit("close");
      }
    },

    close() {
      this.$emit("close");
    },

    getDate(isoDate) {
      return formatISODate(isoDate);
    },

    getStatus(status) {
      switch (status) {
        case "waiting_for_payment":
          return "Ожидает оплаты";
        case "paid":
        case "payed":
          return "Оплачен";
        case "on_the_way":
          return "В пути";
        case "completed":
          return "Завершен";
        case "canceled":
        case "declined":
          return "Отменен";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 10001;

  .modal {
    position: relative;
    height: auto;
    padding: 20px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 5px;
    background: #fff;
    min-width: 700px;
    max-width: 900px;
  }

  .btn-close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 20px;
    color: #c4c4c4;
  }
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.title-wrap {
  .md-title,
  .md-headline {
    text-align: center;
  }

  .md-headline {
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(calc(100% + 10px), -50%);
      cursor: pointer;
      display: flex;
    }
  }

  .subheading-wrap {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
}

.info {
  width: max-content;
}
</style>
